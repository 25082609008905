.portfolioPageScss {
	.homeBannerDiv {
		height: 100%;
	}
	.muteIconDiv {
		position: relative;
		top: -40px;
	}

	/* Extra small devices (phones, 600px and down) */
	@media only screen and (max-width: 600px) {
		.homeBannerDiv {
			height: 100vh;
		}
		.muteIconDiv {
			position: absolute;
			bottom: 40px;
			left: 44%;
			top: auto;
		}
	}

	// /* Small devices (portrait tablets and large phones, 600px and up) */
	// @media only screen and (min-width: 600px) {...}

	// /* Medium devices (landscape tablets, 768px and up) */
	// @media only screen and (min-width: 768px) {...}

	// /* Large devices (laptops/desktops, 992px and up) */
	// @media only screen and (min-width: 992px) {...}

	// /* Extra large devices (large laptops and desktops, 1200px and up) */
	// @media only screen and (min-width: 1200px) {...}
}
