.registerPage {
	.title {
		font-size: 40px;
		margin-bottom: 3rem;
	}

	.mobile_style {
		display: flex;
		width: 100%;
		// border: solid gray 1px;
		border-radius: 5px;

		.mobile_prefix {
			border-top-left-radius: 5px;
			border-top-right-radius: 0px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 0px;
			width: 35% !important;
		}

		.mobile_num {
			border-radius: 0;
			width: 65% !important;

			input {
				border-left: 0px;
				border-right: solid gray 1px;
				border-top: solid gray 1px;
				border-bottom: solid gray 1px;
				border-top-left-radius: 0px;
				border-top-right-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 5px;
			}
		}
	}

	.box_style {
		color: white;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 5px;
	}

	input {
		color: white;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		border: 1px solid rgba(255, 255, 255, 0.5);
	}

	input:focus {
		border: 1px solid #fcb33d;
	}

	option {
		color: black;
		background-color: white;
	}

	textarea {
		color: white;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		border: 1px solid rgba(255, 255, 255, 0.5);
	}

	::placeholder {
		color: white;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: white;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: white;
	}

	.custom-file-upload {
		border: 1px solid rgba(255, 255, 255, 0.5);
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		width: 100%;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.5);
	}

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */
	::-webkit-scrollbar-thumb {
		background: #ff0000;
	}
}


.image_croper_modal {

	.container {
		border: solid red;
	}
}