.buyticket {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	// .overlay {
	// 	height: 100%;
	// 	width: 100%;
	// 	background-color: rgba(0, 0, 0, 0.5);
	// }
	.singleRow {
		margin-bottom: 24px;
	}
	.roww {
		margin: 0px;
	}
	.title {
		font-size: 40px;
		font-weight: bolder;
		margin-bottom: 2rem;
	}
	input {
		color: white;
		// background-color: rgba(0, 0, 0, 0.5);
		background-color: #000000;
		border-radius: 5px;
		border: 1px solid rgba(255, 255, 255, 0.5);
	}
	input:focus {
		border: 1px solid #fcb33d;
	}

	::placeholder {
		color: white;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: white;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: white;
	}

	.main_select {
		color: white;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		option {
			background-color: #101010;
			color: white;
		}
	}
	// .country_list{
	// 	width:100%!important ;
	// 	max-width: 100%!important;
	// 	color: white;
	// 	background-color: rgba(0, 0, 0, 0.5);
	// 	border-radius: 5px;
	// 	border: 1px solid rgba(255, 255, 255, 0.5);

	// }
	// .country_item{
	// 	width:100% ;
	// 	max-width: 100%;
	// 	background-color: #ffffff;
	// }
	@media (min-width: 1440px) and (max-width: 1920px) {
		.container {
			margin-top: 0px;
		}
	}
}

/////////////////////////////////////////////
.card-img-overlay {
	background-color: #36c28596;
	height: 380px;
}

.lnr-checkmark-circle {
	color: #ffffff;
	font-size: 90px;
	margin-top: 16px;
}

.country-list .country {
	padding: 7px 9px;
	// background-color: black;
	color: black;
	&:hover {
		background-color: #fcb33d;
		color: #101010;
	}
	&.highlight {
		background-color: #f1f1f1;
		color: #101010;
	}
}

.form-control {
	// background-color: rgba(0, 0, 0, 0.5)!important;
	background-color: #000000 !important;
	color: white;
	height: 40px !important;
	width: 100% !important;
	border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.react-tel-input {
	color: white !important;
	max-width: 100% !important;
}

.flag-dropdown {
	//   background-color: rgba(0, 0, 0, 0.5)!important;
	color: white !important;
	&:hover,
	&:focus {
		background-color: #fff;
	}
	.selected-flag {
		background: rgb(27, 26, 26) !important;
		border-radius: 3px 0 0 0 !important;
	}
}

.form-control:focus {
	border-color: none;
	box-shadow: none;
}

///////////////////////////////////////////////////
.country_list {
	width: 388px !important ;
	color: white;
	// background-color: rgba(0, 0, 0, 0.5);
	background-color: #000000;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}
.country_item {
	width: 100%;
	max-width: 100%;
	background-color: #ffffff;
}

.dropdown-toggle::after {
	float: right;
	margin-top: 11px;
}

/////////////////MEDIA QUERY////////////////////
@media (min-width: 300px) and (max-width: 462px) {
	.country_list {
		width: 320px !important ;
	}
	.success_modal {
		margin-left: 65px;
	}
	.title {
		font-size: 27px !important;
	}
	.Body_style {
		margin-left: -125px !important;
	}
}
@media (min-width: 559px) and (max-width: 733px) {
	.country_list {
		width: 481px !important ;
	}
	.Body_style {
		margin-left: -24px !important;
	}
}

@media (min-width: 751px) and (max-width: 974px) {
	.country_list {
		width: 654px !important ;
	}
}

/////////////////MEDIA QUERY////////////////////
@media (min-width: 300px) and (max-width: 462px) {
	.country_list {
		width: 320px !important ;
	}
	.success_modal {
		margin-left: 65px;
	}
	.title {
		font-size: 27px !important;
	}
	.Body_style {
		margin-left: -125px !important;
	}

	.title {
		font-size: 42px;
		font-weight: bolder;
		margin-bottom: 2rem;
	}
}
@media (min-width: 559px) and (max-width: 733px) {
	.country_list {
		width: 481px !important ;
	}
	.Body_style {
		margin-left: -24px !important;
	}
}

@media (min-width: 751px) and (max-width: 974px) {
	.country_list {
		width: 654px !important ;
	}
}

@media (min-width: 900px) {
	.toppc {
		margin-top: 1rem;
	}
	.title {
		margin-bottom: 1rem !important;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.country_list {
		width: 288px !important ;
	}
}

//////////////////////////////////////////////////////////////////////////
@media (min-width: 1440px) and (max-width: 1920px) {
	.form_contain {
		margin-top: -190px !important;
	}
}
